import { m } from "framer-motion";

export const profile = {
    'profile_account': 'Cuenta',
    'profile_name': 'Nombre',
    'profile_email': 'Correo electrónico',
    'profile_change_password': 'Cambiar contraseña',
    'profile_phone': 'Número de teléfono',
    'profile_study_info': 'Información del estudio',
    'profile_view_consent': 'Ver consentimiento',
    'profile_opt_out': 'Optar por no participar',
    'profile_opt_out_message': '¿Deseas dejar el estudio?',
    'profile_allow_access_data': 'Mantener mis datos de forma anónima',
    'profile_website': 'Sitio web de investigación',
    'profile_contact': 'Contactar a los investigadores',
    'profile_report_bug': 'Reportar un error',
    'profile_information_note': "Nota informativa"
}
