export const profile = {
    'profile_account': 'Account',
    'profile_name': 'Name',
    'profile_email': 'Email',
    'profile_change_password': 'Change password',
    'profile_phone': 'Phone number',
    'profile_study_info': "Study information",
    'profile_view_consent': "View consent",
    'profile_opt_out': "Opt out",
    'profile_opt_out_message': "Do you want to quit the study?",
    'profile_allow_access_data': "Keep my data anonymously",
    'profile_website': "Research Website",
    'profile_contact': "Contact Researchers",
    'profile_report_bug': 'Report a bug',
    'profile_information_note': "Information Note"
}