import { ReactElement } from "react";
import styled from "styled-components";
import { AnswerValueType } from "../../../types/Answer";
import {
  CheckboxQuestionType,
  DateQuestionType,
  DropdownQuestionType,
  HourQuestionType,
  NumberQuestionType,
  QuestionType,
  QuestionTypeEnum,
  RadioQuestionType,
  SliderQuestionType,
  TextQuestionType,
} from "../../../types/Question";
import AnswerCheckbox from "./Answers/AnswerCheckbox";
import AnswerDate from "./Answers/AnswerDate";
import AnswerDropdown from "./Answers/AnswerDropdown";
import AnswerHour from "./Answers/AnswerHour";
import AnswerNumber from "./Answers/AnswerNumber";
import AnswerRadio from "./Answers/AnswerRadio";
import AnswerSlider from "./Answers/AnswerSlider";
import AnswerText from "./Answers/AnswerText";
import QuestionDisplay from "./QuestionDisplay/QuestionDisplay";
import Card from "../../atoms/Card";
import { useTranslation } from "react-i18next";

interface QuestionProps {
  question: QuestionType;
  answer: AnswerValueType;
  onAnswerChange: (answer: AnswerValueType) => void;
  ruleMessage?: string;
  readText?: (text: string) => void;
}

const componentByQuestionType: Record<
  QuestionTypeEnum,
  (props: QuestionProps) => ReactElement
> = {
  CHECKBOX: ({
    question,
    answer,
    onAnswerChange,
  }: QuestionProps): ReactElement => (
    <AnswerCheckbox
      question={question as CheckboxQuestionType}
      value={answer as number[]}
      onChange={onAnswerChange}
    />
  ),
  RADIOBUTTON: ({
    question,
    answer,
    onAnswerChange,
  }: QuestionProps): ReactElement => (
    <AnswerRadio
      question={question as RadioQuestionType}
      value={answer as string}
      onChange={onAnswerChange}
    />
  ),
  DROPDOWN: ({
    question,
    answer,
    onAnswerChange,
  }: QuestionProps): ReactElement => (
    <AnswerDropdown
      question={question as DropdownQuestionType}
      value={answer as string}
      onChange={onAnswerChange}
    />
  ),
  TEXT: ({ question, answer, onAnswerChange }: QuestionProps): ReactElement => (
    <AnswerText
      question={question as TextQuestionType}
      value={answer as string}
      onChange={onAnswerChange}
    />
  ),
  NUMBER: ({
    question,
    answer,
    onAnswerChange,
  }: QuestionProps): ReactElement => (
    <AnswerNumber
      question={question as NumberQuestionType}
      value={answer as number}
      onChange={onAnswerChange}
    />
  ),
  DATE: ({ question, answer, onAnswerChange }: QuestionProps): ReactElement => (
    <AnswerDate
      question={question as DateQuestionType}
      value={answer as string}
      onChange={onAnswerChange}
    />
  ),
  SLIDER: ({
    question,
    answer,
    onAnswerChange,
  }: QuestionProps): ReactElement => (
    <AnswerSlider
      question={question as SliderQuestionType}
      value={answer as number}
      onChange={onAnswerChange}
    />
  ),
  HOUR: ({ question, answer, onAnswerChange }: QuestionProps): ReactElement => (
    <AnswerHour
      question={question as HourQuestionType}
      value={answer as string}
      onChange={onAnswerChange}
    />
  ),
};


  const Question = ({
    question,
    answer,
    onAnswerChange,
    ruleMessage,
    readText,
  }: QuestionProps): ReactElement => {
    const { type, id, title, description } = question;

  const AnswerComponent = componentByQuestionType[type];

  const { t } = useTranslation();

  const answerComp = (
    <AnswerComponent
      key={id}
      question={question}
      answer={answer}
      onAnswerChange={onAnswerChange}
      readText={readText}
    />
  );

  return (
    <StyledQuestion>
      <QuestionDisplay
        answerComp={answerComp}
        question={question}
        ruleMessage={ruleMessage}
        onReadQuestionClick={(): void => readText(`${title || ''}. ${description || ''}`)}
      />
      <Help>
        <p>{t("help_link_part1")} 
          <a href="https://etude-mentalo.fr/definitions-et-ressources/#aide" target="_blank" style={{ color: 'inherit' }}>{t("help_link_part2")}</a>.
        </p>
      </Help>
      </StyledQuestion>
  );
};

const StyledQuestion = styled.div`
  ion-icon {
    color: ${(props) => props.theme.primary.main};
  }
`;

const Help = styled.div`
  margin: 5px 25px;
`;

export default Question;
