export const profile = {
    'profile_account': 'الحساب',
    'profile_name': 'الاسم',
    'profile_email': 'البريد الإلكتروني',
    'profile_change_password': 'تغيير كلمة المرور',
    'profile_phone': 'رقم الهاتف',
    'profile_study_info': 'معلومات الدراسة',
    'profile_view_consent': 'عرض الموافقة',
    'profile_opt_out': 'إلغاء الاشتراك',
    'profile_opt_out_message': 'هل تريد إنهاء الدراسة؟',
    'profile_allow_access_data': 'حفظ بياناتي بشكل مجهول',
    'profile_website': 'موقع البحث',
    'profile_contact': 'اتصل بالباحثين',
    'profile_report_bug': 'الإبلاغ عن خطأ',
    'profile_information_note': "مذكرة إعلامية"
}
