import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import AuthContext from "../../../../contexts/AuthContext";
import { useConfig } from "../../../../contexts/ConfigContext";
import useToast from "../../../../hooks/useToast";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import { login } from "../../../../services/user.service";
import { IdentificationMethodEnum } from "../../../../types/profile";
import Container from "../../../atoms/Container";
import Subtitle from "../../../atoms/Subtitle";
import VeriticalCenter from "../../../atoms/VerticalCenter";
import EmailLoginFields from "../../../molecules/Home/Login/EmailLoginFields";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import SecretQuestionsForm from "./SecretQuestionsForm";
import ForgotPwdModal from "../../../../pages/Profile/ForgotPasswordModal";
import useQuery from "../../../../hooks/useQuery";
import { useResonanceLayout } from '../../../organisms/ResonnanceLayout/ResoncanceContext';
import { FormGroup } from "../../../atoms/LoginTextField";

const DisplayLogin = (): ReactElement => {
  const { t } = useTranslation();
  const query = useQuery();
  const invitation_token = query.get("invitation");

  const { selectedRole } = useConfig();
  const { goPrevious, welcomeData } = useHomeContext();

  const signinCriterias = selectedRole?.signin_criteria;
  const studyId = welcomeData.id;
  const roleId = selectedRole.id;
  const { method, identification_questions } = signinCriterias;
  const { study_slug } = useParams<{ study_slug: string }>();
  const [isForgotPwdModalOpen, setIsForgotPwdModalOpen] = useState(false);

  const { startSession } = useContext(AuthContext);
  const history = useHistory();
  const present = useToast();

  const { setResonanceLayout } = useResonanceLayout();

  useEffect(() => {
    setResonanceLayout({ 
      title: "home_login_title",
      welcomePage: false,
     });
  }, []);

  // TODO: specify type
  const handleConnect = async (body: any) => {
    try {
      const { data } = await login(studyId, roleId, { ...body, invitation_token });
      await startSession(data.token);
      history.replace(`/${study_slug}/resonance`);
    } catch (e) {
      present("Votre identifiant ou mot de passe est incorrect.");
      console.error(e);
    }
  };

  return (
    <Container>
      <VeriticalCenter>
        <FormGroup>
          <Subtitle>{t("home_login_subtitle")}</Subtitle>
        </FormGroup>

        {method === IdentificationMethodEnum.EMAIL && (
          <EmailLoginFields onConnect={handleConnect} />
        )}
        {method === IdentificationMethodEnum.NICKNAME_PASSWORD && (
          <EmailLoginFields onConnect={handleConnect} withNickName />
        )}
        {method === IdentificationMethodEnum.SECRET_QUESTIONS && (
          <SecretQuestionsForm
            onConnect={handleConnect}
            secretQuestions={identification_questions}
          />
        )}
        {(method === IdentificationMethodEnum.EMAIL || method === IdentificationMethodEnum.NICKNAME_PASSWORD) && <div onClick={() => setIsForgotPwdModalOpen(true)} style={{ textAlign: "center", marginTop: "20px", cursor: "pointer", textDecoration: "underline" }}>
              {t("home_forgot_password")}
        </div>}
      </VeriticalCenter>
      <NextPrevButtons onPreviousClick={goPrevious} />
      <ForgotPwdModal 
          isOpen={isForgotPwdModalOpen}
          onDismiss={() => setIsForgotPwdModalOpen(false)}
        />
    </Container>
  );
};

export default DisplayLogin;
